import React from 'react'
import Layout from '../components/Layout'
import * as styles  from '../styles/project-details.module.css'
import { graphql } from 'gatsby'
import { Row, Col}  from 'react-bootstrap'
import LeftNavBarLaravelTutorial from '../components/examples/laravel/LeftNavBar'

import Seo from '../components/SEO'

export default function ProjectDetails({data}) {

  const { html} = data.tutorial
  const { title, keywords, published, modified, description, type} = data.tutorial.frontmatter

    return (

      <Layout>
          <Seo
            title={title}
            description={description}
            keywords={keywords}
            published={published}
            modified={modified}
            type={type}
          />
          
            <Row>
                <Col xs={12} md={3}>
                  <h2>Tutorials</h2>
                  <LeftNavBarLaravelTutorial></LeftNavBarLaravelTutorial>
                </Col>
                <Col xs={12} md={9}>
                <div className={styles.details}>
                    <h1>{title}</h1>
                    <small>Last modified: {modified}</small>
                    <div className={styles.html} dangerouslySetInnerHTML={{ __html: html }} />
                </div>
                </Col>
            </Row>
          
        </Layout>
    )
}

export const query = graphql`
  query LaravelTutorial($slug: String) {
    tutorial: markdownRemark(frontmatter: {slug: {eq: $slug}}) {
      html
      id
      frontmatter {
        title
        keywords
        description
        published(formatString: "MMMM DD, YYYY")
        modified(formatString: "MMMM DD, YYYY")
        type
        featured
      }
    }
    site:site {
      siteMetadata {
        siteUrl: url
      }
    }
  }
`
